import { axiosPost } from '../api/api.js';

const initCompleteAccountPage = () => {
    $("#completeAccountSubmit").click(_disableButton);
};

const _disableButton = () => {
    $("#completeAccountSubmit").prop("disabled", true);
    $("#spinner").show();
    $("#submitText").hide();
    $("#complete-account").submit();
}

export default initCompleteAccountPage;