const initUpdateEmail = () => {
    const $form = $('#update-email-form');
    if (!$form.length) {
        return;
    }

    $form.on('submit', event => {
        const $submitBtn = $form.find('.js-submit-btn');
        const $spinner = $submitBtn.find('.js-spinner');
        $submitBtn.prop('disabled', true);
        $spinner.show();
    })
}

export default initUpdateEmail;