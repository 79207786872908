import { axiosPost } from '../api/api.js';
import { VerifyAddressApi } from '../api/verifyAddressApi.js'
import VerifyAddressModal from '../modals/verify-address-modal.js';
import { cartCheckoutFailure, cartCheckoutSuccess, displayInvalidForm } from '../helpers/checkout-flow-helpers';

const initMyAccountCheckoutPage = () => {
    const formElement = document.querySelector("form#MyAccountCheckout-Form");

    if (formElement == null)
        return;

    const form = {
        formElement: formElement,
        elements: formElement.elements,
        verificationToken: formElement.querySelector('input[name="__RequestVerificationToken"]')?.value,
        contactDetails: {
            firstNameInput: formElement.querySelector('input[name="FirstName"]'),
            lastNameInput: formElement.querySelector('input[name="LastName"]'),
            emailAddressInput: formElement.querySelector('input[name="Email"]'),
            phoneNumberInput: formElement.querySelector('input[name="PhoneNumber"]')
        },
        addressAndBilling: {
            reasonsForStarting: {
                selectOption: formElement.querySelector('input[type="radio"][name="ReasonForStartingService"][value="select"]'),
                movingOption: formElement.querySelector('input[type="radio"][name="ReasonForStartingService"][value="moving"]'),
                asapOption: formElement.querySelector('input[type="radio"][name="ReasonForStartingService"][value="asap"]'),
                selectedValue: () => formElement.querySelector('input[type="radio"][name="ReasonForStartingService"]:checked')?.value
            },
            datePickers: {
                switchStartInput: formElement.querySelector('input[name="SwitchStartDate"]'),
                switchStartWrapper: formElement.querySelector("div#switch-start-date-picker"),
                moveStartInput: formElement.querySelector('input[name="MoveStartDate"]'),
                moveStartWrapper: formElement.querySelector("div#move-start-date-picker")
            },
            billingAddressCheckbox: formElement.querySelector('input[name="SameBillingAddress"]'),
            billingAddress: {
                wrapper: formElement.querySelector('div#billing-address-form'),
                line1Input: formElement.querySelector('input[name="BillingAddress.Line1"]'),
                suiteInput: formElement.querySelector('input[name="BillingAddress.Suite"]'),
                cityInput: formElement.querySelector('input[name="BillingAddress.City"]'),
                zipCodeInput: formElement.querySelector('input[name="BillingAddress.Zipcode"]'),
                stateSelect: formElement.querySelector('select[name="BillingAddress.State"]')
            },
            autopayCheckbox: formElement.querySelector('input[name="Autopay"]')
        },
        yourAccount: {
            passwordInput: formElement.querySelector('input[name="Password"]'),
            passwordCheckInput: formElement.querySelector('input[name="PasswordCheck"]')
        },
        termsOfService: {
            acceptCheckbox: formElement.querySelector('input[name="AcceptTermsAndConditions"]')
        },
        submitControls: {
            submitText: formElement.querySelector('#checkout-submit-text'),
            submitSpinner: formElement.querySelector('#checkout-submit-spinner'),
            submitButton: formElement.querySelector('#checkout-submit-button')
        },
        modals: {
            verifyAddressModal: document.querySelector("#verifyAddressModal")
        }
    };

    const basicValidationElements = Array.from(form.elements).filter(x => x.type != 'radio'
        && x != form.addressAndBilling.datePickers.moveStartInput
        && x != form.addressAndBilling.datePickers.switchStartInput
        && x != form.yourAccount.passwordCheckInput
        && x != form.addressAndBilling.billingAddress.suiteInput
        && x != form.addressAndBilling.billingAddress.stateSelect
        && x != form.contactDetails.phoneNumberInput);

    basicValidationElements.forEach(elem => {
        elem.addEventListener('input',
            () => setValidity(isElementValid, true, elem));
    });

    initMyAccountContactDetails(form.contactDetails);
    initMyAccountAddressAndBilling(form.addressAndBilling);

    $('i.de-print').click(_printWindow);

    // Form Submit
    formElement.addEventListener("submit", (event) => submitForm(event, "/api/cart/myaccountcheckout", form));
}

const initMyAccountContactDetails = (contactDetailsSection) => {
    contactDetailsSection.phoneNumberInput.addEventListener('input', formatPhoneNumber);
}

const initMyAccountAddressAndBilling = (addressAndBillingSection) => {
    addressAndBillingSection.reasonsForStarting.selectOption
        ?.addEventListener("click", () => updateReasonDatepickers(addressAndBillingSection.reasonsForStarting,
            addressAndBillingSection.datePickers));
    addressAndBillingSection.reasonsForStarting.movingOption
        ?.addEventListener("click", () => updateReasonDatepickers(addressAndBillingSection.reasonsForStarting,
            addressAndBillingSection.datePickers));
    addressAndBillingSection.reasonsForStarting.asapOption
        ?.addEventListener("click", () => updateReasonDatepickers(addressAndBillingSection.reasonsForStarting,
            addressAndBillingSection.datePickers));

    addressAndBillingSection.billingAddressCheckbox
        ?.addEventListener('click', (event) => {
            const isChecked = event.currentTarget.checked;
            addressAndBillingSection.billingAddress.line1Input.required = !isChecked;
            addressAndBillingSection.billingAddress.zipCodeInput.required = !isChecked;
            addressAndBillingSection.billingAddress.cityInput.required = !isChecked;

            if (!isChecked) {
                setValidity((_) => true, false, addressAndBillingSection.billingAddress.line1Input,
                    addressAndBillingSection.billingAddress.zipCodeInput,
                    addressAndBillingSection.billingAddress.cityInput,
                    addressAndBillingSection.billingAddress.stateSelect)
            }
        });

    initMyAccountDatePicker(addressAndBillingSection.datePickers.switchStartInput);
    initMyAccountDatePicker(addressAndBillingSection.datePickers.moveStartInput);

    updateReasonDatepickers(addressAndBillingSection.reasonsForStarting, addressAndBillingSection.datePickers);
}

const initMyAccountDatePicker = (dateInput) => {
    if (dateInput == null)
        return;

    const blackoutDates = dateInput.dataset.blackoutDates;
    const minOffsetDays = dateInput.dataset.minOffsetDays;
    const maxOffsetDays = dateInput.dataset.maxOffsetDays;
    const defaultDate = dateInput.dataset.defaultDate;

    const differenceInTime = new Date(defaultDate).getTime() - new Date().getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    $(dateInput).datepicker({
        beforeShowDay: (date) => {
            const daysInFuture = Math.floor((date - new Date(defaultDate)) / 86400000);
            const dateStr = jQuery.datepicker.formatDate('m/d/yy', date);

            return [
                date.getDay() != 0 && // disable sundays
                daysInFuture >= minOffsetDays && // require the sign-up date be in a certain number of days from now
                daysInFuture <= maxOffsetDays &&
                !blackoutDates.includes(dateStr) // don't allow sign-up on blackout dates
            ];
        },
        defaultDate: defaultDate,
        onClose: (_, picker) => setValidity(isDateValid, true, picker.input.get(0)),
        minDate: differenceInDays - minOffsetDays, // don't allow going below the minimum offset (don't even allow going to those months)
        maxDate: differenceInDays + maxOffsetDays // ... or the maximum offset
    });
}

const isElementValid = (input) => input.checkValidity();

const isDateValid = (input) => {
    const dateformat = /^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/;
    const date = input.value;

    // Matching the date through regular expression      
    if (date.match(dateformat)) {
        let operator = date.split('/');

        // Extract the string into month, date and year      
        let datepart = [];
        if (operator.length > 1) {
            datepart = date.split('/');
        }
        let month = parseInt(datepart[0]);
        let day = parseInt(datepart[1]);
        let year = parseInt(datepart[2]);

        // Create a list of days of a month      
        let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (month == 1 || month > 2) {
            if (day > ListofDays[month - 1]) {
                //to check if the date is out of range     
                return false;
            }
        } else if (month == 2) {
            let leapYear = false;
            if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
            if ((leapYear == false) && (day >= 29)) return false;
            else
                if ((leapYear == true) && (day > 29)) {
                    console.log('Invalid date format!');
                    return false;
                }
        }
    } else {
        console.log("Invalid date format!");
        return false;
    }
    return true;
}

const setValidity = (isValidFunc, markValid, ...elements) => {
    for (const element of elements) {
        if (isValidFunc(element)) {
            if (markValid) element.classList.add('is-valid');
            else element.classList.remove('is-valid')
            element.classList.remove('is-invalid');
        } else {
            element.classList.remove('is-valid');
            element.classList.add('is-invalid');
        }
    }
}

//#region submit functions
const submitForm = (event, url, form) => {
    console.log('submitForm event', event);

    event.preventDefault();
    event.stopImmediatePropagation();

    toggleFormSubmit(form.submitControls, false);

    const selectedReason = form.addressAndBilling.reasonsForStarting.selectedValue();
    const isValidStartDate = validateStartDate(selectedReason, form.addressAndBilling);

    if (isValidStartDate) {
        form.addressAndBilling.datePickers.moveStartInput.setCustomValidity("");
        form.addressAndBilling.datePickers.switchStartInput.setCustomValidity("");
        form.addressAndBilling.datePickers.moveStartInput.classList.remove("is-invalid");
        form.addressAndBilling.datePickers.switchStartInput.classList.remove("is-invalid");
    }

    if (!event.currentTarget.checkValidity() || !isValidStartDate) {
        console.log('validateAndSubmitCheckout validation failure');

        if (!isValidStartDate) {
            const selectedReason = form.addressAndBilling.reasonsForStarting.selectedValue();

            if (selectedReason == "select") {
                form.addressAndBilling.datePickers.switchStartInput.setCustomValidity("Please Select a Start Date");
                form.addressAndBilling.datePickers.switchStartInput.classList.remove("is-valid");
                form.addressAndBilling.datePickers.switchStartInput.classList.add("is-invalid");
            }

            if (selectedReason == "moving") {
                form.addressAndBilling.datePickers.moveStartInput.setCustomValidity("Please Select a Start Date");
                form.addressAndBilling.datePickers.moveStartInput.classList.remove("is-valid");
                form.addressAndBilling.datePickers.moveStartInput.classList.add("is-invalid");
            }
        }

        displayInvalidForm(event);
        toggleFormSubmit(form.submitControls, true);
        return;
    }

    const currentAddress = getBillingAddress(form.addressAndBilling.billingAddress);
    const chainStart = form.addressAndBilling.billingAddressCheckbox.checked
        ? Promise.resolve({ hasExactMatch: true })
        : VerifyAddressApi.suggestAddresses(currentAddress, form.verificationToken);

    chainStart.then((res) => {
        if (res.hasExactMatch)
            return currentAddress;

        return VerifyAddressModal.open(form.modals.verifyAddressModal, res.enteredAddress, res.suggestedAddresses);
    }).then((simpleAddress) => {
        if (simpleAddress == currentAddress)
            return;

        setBillingAddress(form.addressAndBilling.billingAddress, simpleAddress);
        console.log(simpleAddress);
    }, (err) => {
        toggleFormSubmit(form.submitControls, true);
        scrollToSection(form.addressAndBilling.billingAddress.wrapper, 180);
        return Promise.reject(err);
    }).then(() => {
        const formData = new FormData(form.formElement);

        axiosPost(url, formData, (res) => {
            cartCheckoutSuccess(res);
        }, (err) => {
            cartCheckoutFailure(err);
        });

        form.contactDetails.firstNameInput.readonly = true;
        form.contactDetails.lastNameInput.readonly = true;
    }, (reason) => {
        console.log('submission cancelled, reason ', reason);
    });
}

const getBillingAddress = (billingAddress) => {
    return {
        line1: billingAddress.line1Input?.value,
        suite: billingAddress.suiteInput?.value,
        city: billingAddress.cityInput?.value,
        state: billingAddress.stateSelect.options[billingAddress.stateSelect.selectedIndex]?.value,
        zipcode: billingAddress.zipCodeInput?.value
    }
}

const setBillingAddress = (billingAddress, simpleAddress) => {
    billingAddress.line1Input.value = simpleAddress.line1;
    billingAddress.suiteInput.value = simpleAddress.suite;
    billingAddress.cityInput.value = simpleAddress.city;
    billingAddress.zipCodeInput.value = simpleAddress.zipcode;
    billingAddress.stateSelect.value = simpleAddress.state;
}

const validateStartDate = (reason, addressAndBilling) => {
    switch (reason) {
        case "asap":
            return true;
        case "select":
            return isDateValid(addressAndBilling.datePickers.switchStartInput);
        case "moving":
            return isDateValid(addressAndBilling.datePickers.moveStartInput);
        default:
            console.log("Unknown Reason value", reason);
            return false;
    }
}
//#endregion

const scrollToSection = (element, offset) => {
    $('html, body').animate({
        scrollTop: $(element).offset().top - offset
    }, 300);
}

const toggleFormSubmit = (submitControls, enabled) => {
    if (enabled) {
        $(submitControls.submitText).show();
        $(submitControls.submitSpinner).hide();
    }
    else {
        $(submitControls.submitText).hide();
        $(submitControls.submitSpinner).show();
    }

    submitControls.submitButton.disabled = !enabled;
}

//#region default input functions

const _printWindow = () => {
    window.print();
    return false;
}
//#endregion

//#region custom input functions
const updateReasonDatepickers = (reasonsForStarting, datePickers) => {
    switch (reasonsForStarting.selectedValue()) {
        case "select":
            datePickers.moveStartInput.required = false;
            datePickers.moveStartInput.value = '';
            datePickers.moveStartInput.classList.remove('is-invalid', 'is-valid');
            $(datePickers.moveStartWrapper).collapse('hide');

            datePickers.switchStartInput.required = true;
            $(datePickers.switchStartWrapper).collapse('show');
            break;
        case "moving":
            datePickers.switchStartInput.required = false;
            datePickers.switchStartInput.value = '';
            datePickers.switchStartInput.classList.remove('is-invalid', 'is-valid');
            $(datePickers.switchStartWrapper).collapse('hide');

            datePickers.moveStartInput.required = true;
            $(datePickers.moveStartWrapper).collapse('show');
            break;
        case "asap":
        default:
            datePickers.switchStartInput.required = false;
            datePickers.switchStartInput.value = '';
            datePickers.switchStartInput.classList.remove('is-invalid', 'is-valid');
            $(datePickers.switchStartWrapper).collapse('hide');

            datePickers.moveStartInput.required = false;
            datePickers.moveStartInput.value = '';
            datePickers.moveStartInput.classList.remove('is-invalid', 'is-valid');
            $(datePickers.moveStartWrapper).collapse('hide');
            break;
    }
}

const formatPhoneNumber = (event) => {
    var cleaned = ('' + event.target.value).replace(/\D/g, '');
    var partialMatch = cleaned.match(/^(1|)?(\d{3})(\d+)/);
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    var value = event.target.value;
    if (partialMatch) {
        var intlCodePartial = (partialMatch[1] ? '+1 ' : '');
        value = [intlCodePartial, '(', partialMatch[2], ') ', partialMatch[3]].join('');
    }
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        value = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    event.target.value = value;

    setValidity(isElementValid,
        true, event.currentTarget)
}

//#endregion

export default initMyAccountCheckoutPage;