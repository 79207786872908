const initCartSearchPage = () => {
    const $hcTable = $('.cart-search-wrapper');
    if (!$hcTable) {
        return;
    }

    $(document).ready(function () {
        var data = $("#cart-data").html();

        var searchCartTable = $('#cart-search-table').DataTable({
            data: JSON.parse(data),
            dom: 'Brt<"dataTablesFooter"l<"rightSide"ip>>',
            lengthMenu: [[10, 25, 50, 100, 150, 250, 500, -1], [10, 25, 50, 100, 150, 250, 500, "All"]],
            pageLength: 10,
            columns: [
                {
                    data: "OrderId", title: "Cart Id", orderable: true, render: function (data) {
                        return `<a href="/EPiServer/Commerce/OrderManagement#/carts/${data}?tab=summary">${data}</a>`
                    }
                },
                { data: "CustomerName", title: "Customer Name", orderable: true },
                { data: "CreatedDate", type: "date", title: "Created Date", orderable: true, render: function (data) { var date = new Date(data); return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}` } },
                { data: "OrderStatus", title: "Status", orderable: true, render: function (data) { return data.Name; } },
                { data: "OrderEmail", title: "Email", orderable: true, },
                { data: "OrderName", title: "Cart Name", orderable: true },
                { data: "Address", title: "Address", orderable: true },
                { data: "MeterNumber", title: "Meter Number", orderable: true },
                { data: "AffiliateCode", title: "Affiliate Name", orderable: true }
            ],
            buttons: [
                { extend: 'csvHtml5', text: '<i class="fa fa-download"></i>Download CSV', className: 'btn-link', exportOptions: { columns: ":not(.no-export)" } }
            ],
            order: [4, 'desc'],
            paging: true,
            searching: true,
            info: true,
            pagingType: 'simple_numbers',
            scrollY: false,
            scrollX: false,
            processing: true,
            language: {
                paginate: {
                    previous: "<i class='fa fa-angle-left'></i>",
                    next: "<i class='fa fa-angle-right'></i>"
                },
                lengthMenu: "Show _MENU_ Entries",
                info: "Showing <span class='bold'> _START_ </span>- <span class='bold'> _END_ </span> of <span class='bold'> _MAX_ </span>",
                infoFiltered: ""
            }
        });
        $('#cart-search-table-input').on('keyup', function () {
            searchCartTable.search(this.value).draw();
        });
        $('#days').on('change', function () {
            const $spinner = $('.js-spinner');
            $("#cart-search-table_wrapper").hide();
            $("#cart-search-table-input").hide();
            $spinner.show();

            const queryData = new URLSearchParams(window.location.search);
            queryData.set("days", this.value);
            const newUrl = new URL(window.location.href);
            newUrl.search = queryData;

            window.location.href = newUrl;
        });
        $('#status').on('change', function () {
            const $spinner = $('.js-spinner');
            $("#cart-search-table_wrapper").hide();
            $("#cart-search-table-input").hide();
            $spinner.show();

            const queryData = new URLSearchParams(window.location.search);
            queryData.set("statusId", this.value);
            const newUrl = new URL(window.location.href);
            newUrl.search = queryData;

            window.location.href = newUrl;
        });
        $('#clear-status').on('click', function () {
            const $spinner = $('.js-spinner');
            $("#cart-search-table_wrapper").hide();
            $("#cart-search-table-input").hide();
            $spinner.show();

            const queryData = new URLSearchParams(window.location.search);
            queryData.delete("statusId");
            const newUrl = new URL(window.location.href);
            newUrl.search = queryData;

            window.location.href = newUrl;
        });
    });
}
export default initCartSearchPage;