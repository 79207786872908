import axios from 'axios';

import { initializePaymentForm } from '../helpers/enrollment-payment-helpers.js';

const submitPayByToken = ($form, isCsr) => {
    const endpoint = isCsr ? 'csrDepositOrder' : 'affiliateDeposit';

    const $submitBtn = $form.find('button[type=submit]');
    const $spinner = $form.find('.js-spinner');

    const csrfToken = $form.find('input[name="__RequestVerificationToken"]').val();

    $submitBtn.prop('disabled', true);
    $spinner.show();
    const data = {
        name: $form.find('[name=method-name]').val(),
        type: $form.find('[name=chosen-method]').val(),
        token: $form.find('[name=token]').val(),
        expMonth: $form.find('[name=expiry-month]').val() || null,
        expYear: $form.find('[name=expiry-year]').val() || null,
        number: $form.find('[name=acct-number]').val(),
        orderId: $form.find('[name=OrderId').val()
    };

    axios.post(`/api/cart/${endpoint}`, data, {
        headers: {
            RequestVerificationToken: csrfToken
        }
    })
        .then(res => {
            if (res.error) {
                $form.trigger("depositError", [res.error]);
                $form.find('.js-denied-modal').modal('show');
                return;
            }

            $form.trigger("deposit", [{ ...data }]);

            window.location = res.data.href;
        })
        .catch(err => {
            $form.trigger("depositError", [err]);
            $form.find('.js-error-modal').modal('show');
        })
        .finally(() => {
            $spinner.hide();
            $submitBtn.prop('disabled', false);
        });
}

const submitPayByPhone = ($form) => {
    const csrfToken = $form.find('input[name="__RequestVerificationToken"]').val();

    const $submitBtn = $form.find('button[type=submit]');
    const $spinner = $form.find('.js-spinner');
    const $orderId = $form.find('[name=OrderId').val();

    $submitBtn.prop('disabled', true);
    $spinner.show();

    axios.post(`/api/cart/order-pay-by-phone/${$orderId}`, null, {
        headers: {
            RequestVerificationToken: csrfToken
        }
    })
        .then(res => {
            $form.trigger("deposit", [{ endpoint: "pay-by-phone" }]);
            window.location.href = res.data.href;
        })
        .catch(err => {
            $form.trigger("depositError", [err]);
            $form.find('.js-error-modal').modal('show');
        })
        .finally(() => {
            $spinner.hide();
            $submitBtn.prop('disabled', false);
        });
};

const submitDepositExempt = ($form) => {
    const csrfToken = $form.find('input[name="__RequestVerificationToken"]').val();

    const $submitBtn = $form.find('button[type=submit]');
    const $spinner = $form.find('.js-spinner');
    const $orderId = $form.find('[name=OrderId').val();

    $submitBtn.prop('disabled', true);
    $spinner.show();

    axios.post(`/api/cart/csr-order-deposit-exempt/${$orderId}`, null, {
        headers: {
            RequestVerificationToken: csrfToken
        }
    })
        .then(res => {
            $form.trigger("deposit", [{ endpoint: "csr-deposit-exempt" }]);
            window.location.href = res.data.href;
        })
        .catch(err => {
            $form.trigger("depositError", [err]);
            $form.find('.js-error-modal').modal('show');
        })
        .finally(() => {
            $spinner.hide();
            $submitBtn.prop('disabled', false);
        });
};

const initAffiliateDepositPage = () => {
    const $container = $('.js-deposit-page');
    if (!$container.length) {
        return;
    }

    const $form = $container.find('#affiliate-deposit-form');
    const isCsr = !!$form.data('csr');

    // form submit
    $form.on('submit', event => {
        event.preventDefault();

        const method = $form.find('[name=chosen-method]').val();

        if (['cc', 'ach', 'deposit-method'].includes(method)) {
            submitPayByToken($form, isCsr);
        } else if (method === 'phone') {
            submitPayByPhone($form);
        } else if (method === 'deposit-exempt') {
            submitDepositExempt($form);
        }
    });

    initializePaymentForm($form);
}

export default initAffiliateDepositPage;