import axios from 'axios';
import { initializePaymentForm } from '../helpers/enrollment-payment-helpers';
import getRollbar from '../globals/rollbar';

const initAffiliateAutoPayEnrollment = () => {
    const $container = $('.js-autopay-enrollment');
    if (!$container.length) {
        return;
    }

    const $form = $container.find('#affiliate-autopay-enrollment-form');
    const $submitBtn = $container.find('button[type=submit]');
    const $skipBtn = $container.find('.js-skip-button');
    const $submitSpinner = $submitBtn.find('.js-spinner');
    const $skipSpinner = $skipBtn.find('.js-spinner');

    // form submit
    const csrfToken = $form.find('input[name="__RequestVerificationToken"]').val();
    $form.on('submit', event => {
        event.preventDefault();

        $submitBtn.prop('disabled', true);
        $skipBtn.prop('disabled', true);
        $submitSpinner.show();

        const data = {
            useDepositMethod: $form.find('[name=chosen-method]').val() === 'deposit-method',
            name: $form.find('[name=method-name]').val(),
            type: $form.find('[name=chosen-method]').val(),
            token: $form.find('[name=token]').val(),
            expMonth: $form.find('[name=expiry-month]').val() || null,
            expYear: $form.find('[name=expiry-year]').val() || null,
            number: $form.find('[name=acct-number]').val(),
            orderId: $form.find('[name=OrderId]').val(),
            isCsr: $form.find('[name=IsCsr]').val() == "True"
        };

        axios.post(`/api/cart/affiliateAddAutopay`, data, {
            headers: {
                RequestVerificationToken: csrfToken
            }
        })
            .then(res => {
                $form.trigger("addAutopay", [data]);
                if (res.data.error) {
                    getRollbar().error(`auto-pay-enrollment: Error when calling ${endpoint}: ${error}`);
                    $form.trigger("addAutopayError", [err]);
                    $form.find('.js-error-modal').modal('show');
                    $submitBtn.prop('disabled', false);
                    $skipBtn.prop('disabled', false);
                    $submitSpinner.hide();
                }
                else {
                    window.location = res.data.href;
                }
            })
            .catch(err => {
                $form.trigger("addAutopayError", [err]);
                getRollbar().error(`auto-pay-enrollment: Error when calling ${endpoint}: ${error}`);
                $form.find('.js-error-modal').modal('show');
            })
            .finally(() => {
                $submitBtn.prop('disabled', false);
                $skipBtn.prop('disabled', false);
                $submitSpinner.hide();
            });
    });

    
    $skipBtn.on('click', event => {
        event.preventDefault();

        $submitBtn.prop('disabled', true);
        $skipBtn.prop('disabled', true);
        $skipSpinner.show();

        axios.post(`/api/cart/affiliateSkipAutopay/${$form.find('[name=OrderId]').val()}/${$form.find('[name=IsCsr]').val()}`, null, {
            headers: {
                RequestVerificationToken: csrfToken
            }
        })
            .then(res => {
                $form.trigger("skipAutoPay");
                window.location = res.data.href;
            })
            .catch(err => {
                $form.trigger("skipAutoPayError", err);
                $form.find('.js-error-modal').modal('show');
            })
            .finally(() => {
                $submitBtn.prop('disabled', false);
                $skipBtn.prop('disabled', false);
                $skipSpinner.hide();
            });
    });

    initializePaymentForm($form);
};

export default initAffiliateAutoPayEnrollment;
