import initGridBlock from '../components/grid-block.js'
import initICheckTestPage from '../pages/ichecktestpage.js';
import initCompleteAccountPage from '../pages/complete-account-page.js';
import initCheckoutPage from '../pages/checkout.js';
import initDepositPage from '../pages/deposit.js';
import initAccountInformationPage from '../pages/account-information.js';
import initFullAddressModal from '../sections/full-address-modal.js';
import initPlanLoader from '../pages/plan-loader.js';
import initZipCodeCtaBlock from '../sections/zipcode-cta-block';
import initPayBillPage from '../pages/pay-bill.js';
import initAutoPayEnrollment from '../pages/auto-pay-enrollment.js';
import initCsrBanner from '../sections/csr-banner.js';
import initBackToTopButton from '../globals/back-to-top-button.js';
import initBlogComponents from '../components/blog.js';
import initTestimonials from '../components/testimonials.js';
import initPlanBillHistory from '../pages/plan-bill-history.js';
import initTabsContainer from '../components/tabs-container.js';
import initAnnouncementBar from '../globals/announcement-bar.js';
import initMobileHeader from '../globals/mobile-header.js';
import initLiveChat from '../globals/live-chat.js';
import initCommerceButtons from '../globals/commerce-buttons.js';
import initAddressTestPage from '../pages/address-test-page.js';
import initEnergyBotTestPage from '../pages/energy-bot-test-page.js';
import initPlanDetailPage from '../pages/plan-detail-page.js';
import initViewAllPlansPage from '../pages/view-all-plans-page.js';
import initCustomerLookupPage from '../pages/customer-lookup-page.js';
import initAffiliateDepositPage from '../pages/affiliate-deposit.js';
import initAffiliateAutoPayEnrollment from '../pages/affiliate-auto-pay-enrollment.js';
import initSummaryPage from '../pages/summary.js';
import initAdminApiPages from '../pages/admin-api-pages.js';
import initUpdateEmail from '../pages/update-email-page';
import initCreateSmallBusinessPage from '../pages/create-small-business-page.js';
import initChangeUserPasswordPage from '../pages/change-user-password-page.js';
import initCartSearchPage from '../pages/cart-search.js';
// My account

import initMyAccountMain from '../pages/my-account-main.js';
import initAddNewAddressPage from '../pages/my-account-add-address.js';
import initMyAccountCheckout from '../pages/my-account-checkout.js';
import initAffiliateCompleteAccount from '../pages/affiliate-complete-account.js';
import initHealthCheckPage from '../pages/health-check.js';

const initPageHelpers = () => {
    initICheckTestPage();
    initCompleteAccountPage();
    initCheckoutPage();
    initDepositPage();
    initPlanLoader();
    initPlanBillHistory();
    initAccountInformationPage();
    initPayBillPage();
    initAutoPayEnrollment();
    initAddressTestPage();
    initEnergyBotTestPage();
    initPlanDetailPage();
    initViewAllPlansPage();
    initCustomerLookupPage();
    initAffiliateDepositPage();
    initAffiliateAutoPayEnrollment();
    initSummaryPage();
    initAdminApiPages();
    initHealthCheckPage();
    initCartSearchPage();

    // My Account
    initMyAccountMain();
    initAddNewAddressPage();
    initMyAccountCheckout();
    initUpdateEmail();
    initCreateSmallBusinessPage();
    initChangeUserPasswordPage();
    initAffiliateCompleteAccount();
};

const initComponents = () => {
    initGridBlock();
    initZipCodeCtaBlock();
    initFullAddressModal();
    initBlogComponents();
    initTestimonials();
    initTabsContainer();
};

const initGlobals = () => {
    initCsrBanner();
    initAnnouncementBar();
    initMobileHeader();
    initBackToTopButton();
    initLiveChat();
    initCommerceButtons();
};

export const initPage = function () {
    initPageHelpers();
    initComponents();
    initGlobals();
};