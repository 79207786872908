import axios from 'axios';
const tryParseJson = text => {
    try {
        return JSON.parse(text);
    } catch {
        return undefined;
    }
}

const initAddNewAddressPage = () => {
    const $container = $('#AccountAddAddress');
    const $currentForm = $("form", $container);
    const $addressSelector = $("#address-search");
    const $addressContainer = $("#CleanAddressDisplayContainer");
    const $loadingMessage = $(".loading-message");
    const $cleanAddressContainer = $("#CleanAddressDisplay");
    const $addressError = $("#AddressErrorMessage");
    const $esiidError = $("#ESIIDErrorMessage");
    const $verifyingAddress = $("#AddressLoader");

    if (!$container.length || !$currentForm.length) {
        return;
    }

    const $esiIdInput = $currentForm.find('[name=EsiId]');
    const $utilityNameInput = $currentForm.find('[name=UtilityName]')
    const $addressInput = $currentForm.find('[name=StreetAddress]');
    const $cityInput = $currentForm.find('[name=City]')
    const $stateInput = $currentForm.find('[name=State]');
    const $zipInput = $currentForm.find('[name=Zip]');
    const $zipPlusFourInput = $currentForm.find('[name=ZipPlusFour]');
    const $businessInput = $currentForm.find('[name=business]');
    const $premiseType = $currentForm.find('[name=premiseType]');

    window.addEventListener('message', event => {
        if (event && event.data) {
            const data = tryParseJson(event.data);

            if (data === undefined) {
                return;
            }

            if (data.channel !== 'eb-esid-search') {
                return;
            }

            if (data.type === 'address-selected') {
                try {
                    setFormValues(
                        data.utilityAccountNumber,
                        data.utility,
                        data.address.street,
                        data.address.city,
                        data.address.state,
                        data.address.zipCode,
                        data.address.zipCodePlusFour,
                        data.business,
                        data.displayAddress);

                    $verifyingAddress.removeClass("d-none");
                    $addressSelector.addClass("d-none");

                    const restData = {
                        esiId: data.utilityAccountNumber,
                        utilityName: data.utility,
                        streetAddress: data.address.street,
                        city: data.address.city,
                        state: data.address.state,
                        zip: data.address.zipCode,
                        zipPlusFour: data.address.zipCodePlusFour,
                        premiseType: $premiseType.val(),
                    };
                    debugger;

                    axios.post(`/api/address/updateaddresswithesiid`, restData)
                        .then((response) => {
                            if (response.data.error) {
                                $esiidError.addClass("d-none");
                                $addressError.addClass("d-none");

                                switch (response.data.error) {
                                    case "unavailable":
                                        $verifyingAddress.addClass("d-none");
                                        $addressContainer.removeClass("d-none");
                                        $addressError.removeClass("d-none");
                                        break;
                                    case "ESIIDError":
                                        $verifyingAddress.addClass("d-none");
                                        $addressContainer.removeClass("d-none");
                                        $esiidError.removeClass("d-none");
                                        break;
                                }
                            } else if (response.data.continuationUrl) {
                                $loadingMessage.text($loadingMessage.data("plans"))
                                $currentForm.submit();
                            }
                        })
                        .catch((err) => {
                            console.log("error-" + err);
                        });
                } catch (e) {
                    console.log("*** energybot address error ***" + e);
                }
            }
        }
    })

    $addressContainer.on("click", ".js-choose-another-address", function (e) {
        $addressContainer.addClass("d-none");
        $addressSelector.removeClass("d-none");
        setFormValues("", "", "", "", "", "", "", "", "");
    })

    let setFormValues = function (esiid, utilityName, streetAddress, city, state, zip, zipPlusFour, business, cleanAddressDisplay) {
        $esiIdInput.val(esiid);
        $utilityNameInput.val(utilityName);
        $addressInput.val(streetAddress);
        $cityInput.val(city);
        $stateInput.val(state);
        $zipInput.val(zip);
        $zipPlusFourInput.val(zipPlusFour);
        $businessInput.val(business);
        $cleanAddressContainer.text(cleanAddressDisplay);
    }
};

export default initAddNewAddressPage;