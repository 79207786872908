import axios from 'axios';
import * as ld from 'lodash-es/lodash';
import getRollbar from '../globals/rollbar';

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const processCreditCardWithToken = (token, amount, salesTax, proccesCreditCardCb) => {
    const request = '/api/icheckpayment/processcc';

    if (parseFloat(amount) < 0)
        amount = '0.00';
    
    if (parseFloat(salesTax) < 0)
        salesTax = '0.00';
    
    const body = {
        "token": token,
        "amount": amount,
        "salesTax": salesTax
    };

    axios.post(request, body)
        .then(function (response) {
            console.log('/api/icheckpayment/processcc response => ' + JSON.stringify(response.data));

            proccesCreditCardCb(response.data);
        })
        .catch(function (error) {
            console.error('/api/icheckpayment/processcc error =>' + JSON.stringify(error));
        });
};

// based on icheck's sample code: https://portals.icheckdev.com/iframe/creditcardsample.html
export const initICheckIFrameEvents = (iframeSubmitCb) => {  
    if (window.addEventListener) {
        window.addEventListener('message', (e) => {
            var testCookie = getCookie("ichecktest");
            if (testCookie) {
                debugger;
            }

            const data = ld.get(e, 'data');            
            if (ld.isEmpty(data) || !ld.isObject(data))
                return;
            
            const resp = responseToValidationData(data);
            console.log(`iCheck response ichk: "${JSON.stringify(resp)}"`);

            if (resp.approved) {
                getRollbar().info(`Received OK iCheck response\n${JSON.stringify(data)}`);
            } else {
                getRollbar().warn(`Received Decline iCheck response\n${JSON.stringify(data)}`);
            }
            
            const source = ld.get(data, 'source');

            if (ld.isEmpty(source) || source !== 'iCheck')
                return;
            
            const error = ld.get(data, 'error');
            
            if (!ld.isEmpty(data.error)) {
                getRollbar().warn(`iCheck response error\n${JSON.stringify(data)}`);
                console.error('iCheck response error => ' + JSON.stringify(error));
            } else {
                //console.log('icheck iframe submit response json =>' + JSON.stringify(data));
            }

            // make callback with iframe submission data 
            iframeSubmitCb(data);
        });
    }
};

// based on icheck's sample code: https://portals.icheckdev.com/iframe/creditcardsample.html
/* 
* #1 - "Your transaction could not be processed. Please try again later." - System Exception / iCheck is down / Network issues
* #2 - "Your transaction was declined. Please check your payment details/credit card number."
*  - "DECLINED|CreditCardNumber Invalid"
* 
* #3 - "Your transaction was not able to be processed. Please contact customer care at 833-667-6468 for assistance."
*  - "DECLINED|INVALID PROCESSOR"
*  - "DECLINED|ICG PREVENT"
*
* #4 - "Your transaction was declined. Please verify your address / zip code is entered correctly."";
* #5 - "Your transaction was declined. Please verify your CVV is entered correctly."";
* #6 - "Your transaction was declined. Please verify your address / zip code, and CVV are entered correctly.";
*/
export const responseToValidationData = response => {
    var testCookie = getCookie("ichecktest");
    if (testCookie) {
        debugger;
        if (testCookie == "forcepass") {
            return {
                approved: true,
                avsPass: true,
                cvvPass: true,
                ccFail: false,
                declined: false
            }
        } else if (testCookie == "forceccfail") {
            return {
                approved: false,
                avsPass: true,
                cvvPass: true,
                ccFail: true,
                declined: true
            }
        } else if (testCookie == "forcedeclined") {
            return {
                approved: false,
                avsPass: true,
                cvvPass: true,
                ccFail: false,
                declined: true
            }
        } else if (testCookie == "forceavsfail") {
            var retVal = {
                approved: true,
                avsPass: false,
                cvvPass: true,
                ccFail: false,
                declined: false
            }
        } else if (testCookie == "forcecvvfail") {
            return {
                approved: true,
                avsPass: true,
                cvvPass: false,
                ccFail: false,
                declined: false
            }
        } else if (testCookie == "forceavscvvfail") {
            var retVal = {
                approved: false,
                avsPass: true,
                cvvPass: false,
                ccFail: false,
                declined: false,
            }
        }

    }

    if (response.verifyCardResponse) {
        console.log(`iCheck verifyCardReponse ichk: ${JSON.stringify(response.verifyCardResponse)}`);
        const enableAvsCheck = false;
        const parts = response.verifyCardResponse.split('|');

        const groupedParts = ld.mapValues(
            ld.keyBy(parts.slice(1), part => part.split(':')[0]),
            part => ld.trim(ld.get(part.split(':'), 1))
        );

        var approved = parts[0] === 'APPROVED';

        var retVal = {
            approved: false,
            avsPass: false,
            cvvPass: false,
            ccFail: false,
            declined: false,
        }

        if (approved) {
            retVal.avsPass = (enableAvsCheck) ? ld.startsWith(ld.get(groupedParts, 'AVS CODE'), 'Y ') : true;
            retVal.cvvPass = ld.startsWith(ld.get(groupedParts, 'CVV2 CODE'), 'M ');
        } else {
            if (parts[0].startsWith("ERROR - Authorization has been denied")) {
                // TODO: Waiting for icheck on what this means. Show declined message?
                retVal.declined = true;
            } else if (parts[1].toUpperCase() === "CREDITCARDNUMBER INVALID") {
                retVal.ccFail = true;
            } else if (parts[0] === "DECLINED - Serv Not Allowed"
                || parts[1].toUpperCase() === "INVALID PROCESSOR"
                || parts[1].toUpperCase() === "ICG PREVENT") {

                retVal.declined = true;
            }
        }

        retVal.approved = approved && retVal.avsPass && retVal.cvvPass
    
        return retVal;
    } else if (response.verifyCheckResponse) {
        return {
            approved: ld.startsWith(response.verifyCheckResponse, 'APPROVED')
        };
    } else {
        getRollbar().warn(`Unable to parse the javascript response ${JSON.stringify(response)}`);
        // we got something we don't know how to handle
        return {
            approved: false
        };
    }
};

export const expirationDateFrom = cardExpDateRaw => ({
    month: +cardExpDateRaw.substr(0, 2),
    year: +`20${cardExpDateRaw.substr(2, 2)}`
});

export const accountNameFor = (icheckData) => {
    if (icheckData.operation === 'SaveACHToken') {
        return `Bank ending ${icheckData.accountNumber}`;
    } else if (icheckData.operation === 'SaveCCToken') {
        return `${icheckData.cardType} ending ${icheckData.cardNumber} exp. ${icheckData.cardExpDate.substr(0, 2)}/${icheckData.cardExpDate.substr(2, 2)}`;
    }
}

export const denialReasonFor = (validationResp) => {
    var testCookie = getCookie("ichecktest");
    if (testCookie) {
        debugger;
    }

    if (validationResp.ccFail) {
        return 'Your transaction could not be processed. Please check your payment details/credit card number.';
    }

    if (validationResp.declined) {
        return 'Your transaction could not be processed. If you continue to have issues, please give us customer a call at 833-667-6468.'
    }

    if (!validationResp.avsPass && !validationResp.cvvPass) {
        return 'Your transaction could not be processed. Please check your address/zip code and CVV.'
    }

    if (!validationResp.avsPass) {
        return 'Your transaction could not be processed. Please verify your address / zip code is entered correctly.';
    }

    if (!validationResp.cvvPass) {
        return 'Your transaction could not be processed. Please check your CVV.'
    }

    return 'Your transaction could not be processed due to a system issue. Please try again later.';
}