import axios from 'axios';
import getRollbar from '../globals/rollbar';
import { denialReasonFor, expirationDateFrom, initICheckIFrameEvents, responseToValidationData } from '../icheck/icheck';

// TODO: move this into a global location
export function getCookiePb(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

const initPaymentProcessing = ($container) => {
    const csrfToken = $container.find('input[name="__RequestVerificationToken"]').val(); 

    const $submitBtn = $container.find('.js-make-payment-btn');
    const $spinner = $submitBtn.find('.js-spinner');

    $container.find(".js-print-btn").on("click", event => {
        window.print();
    });

    var testCookie = getCookiePb("ichecktest");
    if (testCookie) {
        debugger;
    };

    // logic that triggers whenever we submit the iframes for ACH or credit card (currently goes through the entire flow)
    initICheckIFrameEvents(data => {
        const validationResp = responseToValidationData(data);
        console.log(`icheck response: pb: "${JSON.stringify(validationResp)}"`);

        if (validationResp.approved) {
            const amount = +($container.find('#current_balance').is(':checked') ? $container.data('currentBalance') : $container.find('[name=amount]').val());

            if (amount < 0) {
                $container.find('.js-invalid-amount-modal').modal('show');
                return;
            }

            const paymentMethod = data.operation === 'SaveACHToken' ? 'ach' : 'cc';
            $container.find('[name=icheck-method]').val(paymentMethod);

            if (paymentMethod === 'cc') {
                const expDate = expirationDateFrom(data.cardExpDate);

                $container.find('[name=cc-token]').val(data.token);
                $container.find('[name=cc-expiry-month]').val(expDate.month);
                $container.find('[name=cc-expiry-year]').val(expDate.year);
                $container.find('[name=cc-last4]').val(data.cardNumber);
                $container.find('.js-cc-confirmation-last4').text(data.cardNumber);
                $container.find('.js-cc-expiration').text(data.cardExpDate);

                $container.find('.js-cc-confirmation').show();
                $container.find('#icheck-iframe-cc').hide();
                $submitBtn.prop('disabled', false);
            } else if (paymentMethod === 'ach') {
                $container.find('[name=ach-token]').val(data.token);
                $container.find('[name=ach-last4]').val(data.accountNumber);
                $container.find('.js-ach-number').text(data.accountNumber);

                $container.find('.js-ach-confirmation').show();
                $container.find('#icheck-iframe-ach').hide();
                $submitBtn.prop('disabled', false);
            }
        } else {
            $container.find('.js-denied-modal-text').text(denialReasonFor(validationResp));
            $container.find('.js-denied-modal').modal('show');
        }
    });

    // logic that triggers when we submit an existing payment method (? or is this for new also)
    $container.find('.js-payment-form').on('submit', event => {
        event.preventDefault();

        var testCookie = getCookiePb("ichecktest");
        if (testCookie) {
            debugger;
        };

        const amount = +($container.find('#current_balance').is(':checked') ? $container.data('currentBalance') : $container.find('[name=amount]').val());

        if (amount < 0) {
            $container.find('.js-invalid-amount-modal').modal('show');
            return;
        }

        const icheckMethod = $container.find('[name=icheck-method]').val();
        let req;
        if (icheckMethod) {
            const payload = {
                type: icheckMethod,
                amount
            };
            if (icheckMethod == 'cc') {
                payload.token = $container.find('[name=cc-token]').val();
                payload.expiryMonth = $container.find('[name=cc-expiry-month]').val();
                payload.expiryYear = $container.find('[name=cc-expiry-year]').val();
                payload.number = $container.find('[name=cc-last4]').val();
            } else if (icheckMethod == 'ach') {
                payload.token = $container.find('[name=ach-token]').val();
                payload.number = $container.find('[name=ach-last4]').val();
            }

            getRollbar().info(`pay-bill: Sending iCheckRequest From New Method\n Payload = ${JSON.stringify(payload)}`);

            // using ACH or credit card
            req = axios.post(`/api/payments/FromNewMethod`, payload, { // TODO: consolidate call responses
                headers: {
                    RequestVerificationToken: csrfToken
                }
            });
        } else {
            // using a saved wallet

            getRollbar().info(`pay-bill: Sending iCheckRequest From Existing Method\n Wallet Name = ${$(event.target).find('[name=paymentBy]:checked').val().replace(/^wallet_/, '') }`);

            req = axios.post('/api/payments/FromExistingMethod', {
                amount,
                methodName: $(event.target).find('[name=paymentBy]:checked').val().replace(/^wallet_/, '')
            }, {
                headers: {
                    RequestVerificationToken: csrfToken
                }
            });
        }

        $submitBtn.prop('disabled', true);
        $spinner.show();

        req
            .then(res => {
                // successful payment
                getRollbar().info(`pay-bill: Payment Returned Successfully \n Data = ${JSON.stringify(res.data)}`);

                $container.find('.js-payment-form').hide();
                $container.find('.js-confirmation').show();
                $("#payment-amount").html(`$${res.data.paymentAmount}`);
                $("#payment-name").html(res.data.paymentName);
                $("#payment-date").html(res.data.paymentDate);
                $("#payment-confirmation-id").html(res.data.paymentConfirmationId);
            })
            .catch(err => {
                debugger;
                getRollbar().error(`pay-bill: Payment Returned Error \nMessage = "${err.message}"\nResponse = ${JSON.stringify(err.response.data.detail)}`);
                $container.find('.js-error-detail').text(err.response.data.detail);
                $container.find('.js-error-modal').modal('show');
            })
            .finally(() => {
                $submitBtn.prop('disabled', false);
                $spinner.hide();
            });
    })
}

const initFormElements = ($container) => {
    $container.find('input[type="radio"][name=make-a-payment]').change(function () {
        if ($(this).is(':checked')) {
            const amount = +($container.find('#current_balance').is(':checked') ? $container.data('currentBalance') : $container.find('[name=amount]').val());
            if (amount > 0 && $container.find('#ach, #pay-by-cc, .js-pay-with-wallet').is(':checked')) {
                $container.find('.js-make-payment-btn').prop('disabled', false);
            }
            else {
                $container.find('.js-make-payment-btn').prop('disabled', true);
            }
        }
    });

    $container.find('#pay-by-cc').on('click', event => {
        $container.find('.js-payment-wrapper:not(.js-payment-wrapper-cc)').hide();
        $container.find('.js-payment-wrapper-cc').show();
        $container.find('.js-make-payment-btn').prop('disabled', true);
        $container.find('.js-cc-confirmation').hide();
        $container.find('#icheck-iframe-cc').show();
    });

    $container.find('#ach').on('click', event => {
        $container.find('.js-payment-wrapper:not(.js-payment-wrapper-ach)').hide();
        $container.find('.js-payment-wrapper-ach').show();
        $container.find('.js-make-payment-btn').prop('disabled', true);
        $container.find('.js-ach-confirmation').hide();
        $container.find('#icheck-iframe-ach').show();
    });

    $container.find('.js-pay-with-wallet').on('click', event => {
        const amount = +($container.find('#current_balance').is(':checked') ? $container.data('currentBalance') : $container.find('[name=amount]').val());
        if (amount > 0) {
            $container.find('.js-payment-wrapper').hide();
            $container.find('.js-make-payment-btn').prop('disabled', false);
            $container.find('[name=icheck-method]').val(null);
            $container.find('.js-ach-confirmation').hide();
            $container.find('#icheck-iframe-ach').show();
        }
    });

    $container.find('.js-use-different-cc-btn').on('click', event => {
        event.preventDefault();

        $container.find('.js-make-payment-btn').prop('disabled', true);
        $container.find('.js-cc-confirmation').hide();
        $container.find('#icheck-iframe-cc').show();
        $container.find('[name=icheck-method]').val(null);
    });

    $container.find('.js-use-different-ach-btn').on('click', event => {
        event.preventDefault();

        $container.find('.js-make-payment-btn').prop('disabled', true);
        $container.find('.js-ach-confirmation').hide();
        $container.find('#icheck-iframe-ach').show();
        $container.find('[name=icheck-method]').val(null);
    });

    $container.find('#ach, #pay-by-cc, .js-pay-with-wallet').prop('checked', false);

    $container.find('#other-amount-number').on('focusout', event => {
        var otherAmountInputValue = event.target.value;

        if (otherAmountInputValue != null && Number.parseFloat(otherAmountInputValue) > 0) {
            $("#other-amount").prop('checked', true);
            $("#other-amount").trigger("change");
        }
    });
};

const initPayBillPage = () => {
    const $container = $('.js-pay-bill-container');

    if (!$container.length) {
        return;
    }

    initFormElements($container);
    initPaymentProcessing($container);
};

export default initPayBillPage;
