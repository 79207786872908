import { initICheckIFrameEvents, responseToValidationData, expirationDateFrom, accountNameFor } from '../icheck/icheck';

export const initializePaymentForm = ($form) => {
    // initalize button behavior
    const $useDepositMethodBtn = $form.find('#radio-use-deposit');
    const $payByPhoneBtn = $form.find('#radio-pay-by-phone');
    const $depositExemptButton = $form.find('#radio-deposit-exempt');
    const $ccBtn = $form.find('#radio-add-cc, #radio-add-debit'); // credit and debit card do the same thing
    const $achBtn = $form.find('#radio-add-ach');
    const $payByPhoneArea = $form.find('#pay-by-phone-copy');
    const $depositExemptArea = $form.find('#deposit-exempt-copy');
    const $ccArea = $form.find('#icheck-iframe-cc');
    const $achArea = $form.find('#icheck-iframe-ach');
    const $ccConfirm = $form.find('#cc-confirmation');
    const $achConfirm = $form.find('#ach-confirmation');

    $payByPhoneBtn.on('click', event => {
        $ccArea.hide();
        $achArea.hide();
        $payByPhoneArea.show();
        $depositExemptArea.hide();
        $ccConfirm.hide();
        $achConfirm.hide();
        $submitBtn.show();
        $form.find('[name=chosen-method]').val('phone');
    });

    $depositExemptButton.on('click', event => {
        $ccArea.hide();
        $achArea.hide();
        $payByPhoneArea.hide();
        $depositExemptArea.show();
        $ccConfirm.hide();
        $achConfirm.hide();
        $submitBtn.show();
        $form.find('[name=chosen-method]').val('deposit-exempt');
    });

    $useDepositMethodBtn.on('click', event => {
        $ccArea.hide();
        $achArea.hide();
        $payByPhoneArea.hide();
        $depositExemptArea.hide();
        $ccConfirm.hide();
        $achConfirm.hide();
        $submitBtn.show();
        $form.find('[name=chosen-method]').val('deposit-method');
    });

    $ccBtn.on('click', event => {
        $ccArea.show();
        $achArea.hide();
        $payByPhoneArea.hide();
        $depositExemptArea.hide();
        $ccConfirm.hide();
        $achConfirm.hide();
        $submitBtn.hide();
        $form.find('[name=chosen-method]').val(null);
    });

    $achBtn.on('click', event => {
        $ccArea.hide();
        $achArea.show();
        $payByPhoneArea.hide();
        $depositExemptArea.hide();
        $ccConfirm.hide();
        $achConfirm.hide();
        $submitBtn.hide();
        $form.find('[name=chosen-method]').val(null);
    });

    const $submitBtn = $form.find('button[type=submit]');

    initICheckIFrameEvents(data => {
        const validationResponse = responseToValidationData(data);
        console.log(`iCheck response eph: "${validationResponse}"`);

        if (!validationResponse.approved) {
            $form.find('.js-denied-modal').modal('show');
            return;
        }

        $form.find('[name=token]').val(data.token);
        $form.find('[name=method-name]').val(accountNameFor(data));

        if (data.operation === 'SaveACHToken') {
            $form.find('[name=chosen-method]').val('ach');
            $form.find('[name=acct-number]').val(data.accountNumber);
            
            $form.find('[name=expiry-month]').val(null);
            $form.find('[name=expiry-year]').val(null);

            $form.find('.js-ach-number').text(data.accountNumber);
        } else if (data.operation === 'SaveCCToken') {
            const expDate = expirationDateFrom(data.cardExpDate);

            $form.find('[name=chosen-method]').val('cc');
            $form.find('[name=acct-number]').val(data.cardNumber);
            $form.find('[name=expiry-month]').val(expDate.month);
            $form.find('[name=expiry-year]').val(expDate.year);

            $form.find('.js-cc-number').text(data.cardNumber);
        }
        $form.submit();
    });

    $form.find('button[data-bs-dismiss]').on('click', event => {
        event.preventDefault();
    });
}