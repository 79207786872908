import _ from 'lodash-es/lodash';
import { initICheckIFrameEvents, processCreditCardWithToken } from '../icheck/icheck.js';

const initICheckTestPage = () => {

    if ($('#icheck-test-form').length > 0) {
        console.log('icheck test page detected, initializing icheck...');

        initICheckIFrameEvents(_iframeSubmitCallback);
    }
};

const _iframeSubmitCallback = (data) => {
    console.log('icheck iframe submit callback');

    const token = _.get(data, 'token');
    const accountName = _.get(data, 'accountName');
    const zip = _.get(data, 'zip');
    const custId = _.get(data, 'custId');
    const cardNumber = _.get(data, 'cardNumber');
    const cardExpDate = _.get(data, 'cardExpDate');
    const cardType = _.get(data, 'cardType');
    const source = _.get(data, 'source');
    const operation = _.get(data, 'operation');
    const transactionResponse = _.get(data, 'transactionResponse');
    const verifyCardResponse = _.get(data, 'verifyCardResponse');
    
    console.log(`token: ${token}`);
    console.log(`accountName: ${accountName}`);
    console.log(`zip: ${zip}`);
    console.log(`custId: ${custId}`);
    console.log(`cardNumber: ${cardNumber}`);
    console.log(`cardExpDate: ${cardExpDate}`);
    console.log(`cardType: ${cardType}`);
    console.log(`source: ${source}`);
    console.log(`operation: ${operation}`);
    console.log(`transactionResponse: ${transactionResponse}`);
    console.log(`verifyCardResponse: ${verifyCardResponse}`);

    if (_.isEmpty(token)) {
        console.error("no token returned from icheck iframe");
        return;
    }

    const deposit = _.trim($('#icheck-test-form #deposit').val());

    if (parseFloat(deposit) > 0.01) {
        console.log(`deposit: ${deposit} specified`);
        console.log('processing credit card using token...');
        
        processCreditCardWithToken(token, deposit, '0.00', _processCreditCardWithTokenCallback);
        
        return;
    }

    console.log('no deposit specified, we will *not* process credit card using token');
    
    const tokenizationCompleteHtml =
        '<h2>Tokenization Details:</h2>'
        + '<p>'
        + `Token: ${token}<br>`
        + `Account Name: ${accountName}<br>`
        + `ZIP: ${zip}<br>`
        + `Customer ID: ${custId}<br>`
        + `Card number: ${cardNumber}<br>`
        + `Card expiration: ${cardExpDate}<br>`
        + `Card type: ${cardType}<br>`
        + `Source: ${source}<br>`
        + `Operation: ${operation}<br>`
        + `Transaction response: ${transactionResponse}<br>`
        + `Verifications Response: ${verifyCardResponse}<br>`
        + '</p>';
    
    $('#icheck-iframe-cc').html(tokenizationCompleteHtml);
};

const _processCreditCardWithTokenCallback = (data) => {
    console.log('icheck process credit card with token callback');

    const isApproved = _.get(data, 'isApproved');
    const amount = _.get(data, 'amount');
    const firstName = _.get(data, 'firstName');
    const lastName = _.get(data, 'lastName');
    const creditCardType = _.get(data, 'creditCardType');
    const creditCardLast4 = _.get(data, 'creditCardLast4');
    const expirationDate4Digits = _.get(data, 'expirationDate4Digits');
    const siteID = _.get(data, 'siteID');
    const transactionID = _.get(data, 'transactionID'); 
    const paymentDate = _.get(data, 'paymentDate');

    console.log(`isApproved: ${isApproved}`);
    console.log(`amount: ${amount}`);
    console.log(`firstName: ${firstName}`);
    console.log(`lastName: ${lastName}`);
    console.log(`creditCardType: ${creditCardType}`);
    console.log(`creditCardLast4: ${creditCardLast4}`);
    console.log(`expirationDate4Digits: ${expirationDate4Digits}`);
    console.log(`siteID: ${siteID}`);
    console.log(`transactionID: ${transactionID}`);
    console.log(`paymentDate: ${paymentDate}`);

    const transactionCompleteHtml =
        '<h2>Transaction Details:</h2>'
        + '<p>'
        + `Is Approved: ${isApproved}<br>`
        + `Amount: ${amount}<br>`
        + `First name: ${firstName}<br>`
        + `Last name: ${lastName}<br>`
        + `Credit card type: ${creditCardType}<br>`
        + `Last 4: ${creditCardLast4}<br>`
        + `Expiration: ${expirationDate4Digits}<br>`
        + `Site ID: ${siteID}<br>`
        + `Transaction ID: ${transactionID}<br>`
        + `Payment date: ${paymentDate}<br>`
        + '</p>';

    $('#icheck-iframe-cc').html(transactionCompleteHtml);
};

export default initICheckTestPage;