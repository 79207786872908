const initPayBillModal = () => {
    const payBillButton = document.querySelector('.btn-pay-bill');
    const confirmModal = document.querySelector('.js-confirm-make-payment-modal');
    const confirmButton = document.querySelector('.js-confirm-make-payment-button');

    // if user is enrolled in Auto Pay, show modal asking user to confirm whether or not they want to continue to Pay Bill page else go straight to Pay Bill page
    document.querySelectorAll('.btn-pay-bill').forEach(btn => btn.addEventListener('click', (event) => {
        const isEnrolled = event.currentTarget.dataset.autopayEnrolled;

        if (isEnrolled != null) {
            $(confirmModal).modal('show');
        } else {
            window.location.href = event.currentTarget.dataset.payBillPageUrl;
        }
    }));

    // navigate to Pay Bill page if user confirms they want to make a one-time payment
    confirmButton?.addEventListener('click', () => {
        window.location.href = payBillButton.dataset.payBillPageUrl;
    });
};

const initMyAccountMain = () => {
    initPayBillModal();
};

export default initMyAccountMain;
